import { TEnv } from './types';

export const CONF_ENVIRONMENT: TEnv = process.env.REACT_APP_NODE as TEnv;
export const CONF_URL_CONSULTATION = process.env.REACT_APP_URL_CONSULTATION;
export const CONF_API_URL = process.env.REACT_APP_API_URL;
export const CONF_SEO_URL = process.env.REACT_APP_SEO_URL;
export const CONF_UPLOAD = process.env.REACT_APP_S3_URL;
export const CONF_MAP_API = process.env.REACT_APP_MAP_API;
export const CONF_DSN_SENTRY = process.env.REACT_APP_SENTRY_DSN;

// zoom settings
export const CONF_ZOOM_KEY = process.env.REACT_APP_ZOOM_KEY;
export const CONF_ZOOM_SECRET = process.env.REACT_APP_ZOOM_SECRET;
export const CONF_ZOOM_HOST = process.env.REACT_APP_ZOOM_HOST;

// hubspot
export const CONF_HUBSPOT_FORM_ID = process.env.REACT_APP_HUBSPOT_FORM_ID;
export const CONF_HUBSPOT_PORTAL_ID = process.env.REACT_APP_HUBSPOT_PORTAL_ID;
