import React, { FC, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop: FC<{ children: ReactNode }> = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.getElementById('main')?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return <>{children}</>;
};

export default ScrollToTop;
