import React, { FC, ReactChild, ReactNode } from 'react';
import clsx, { ClassValue } from 'clsx';

type TStackSpace = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

const spaceY = {
  0: 'space-y-0',
  1: 'space-y-1',
  2: 'space-y-2',
  3: 'space-y-3',
  4: 'space-y-4',
  5: 'space-y-5',
  6: 'space-y-6',
  7: 'space-y-7',
  8: 'space-y-8',
  9: 'space-y-9',
  10: 'space-y-10'
};

const spaceX = {
  0: 'space-x-0',
  1: 'space-x-1',
  2: 'space-x-2',
  3: 'space-x-3',
  4: 'space-x-4',
  5: 'space-x-5',
  6: 'space-x-6',
  7: 'space-x-7',
  8: 'space-x-8',
  9: 'space-x-9',
  10: 'space-x-10'
};

interface IStack {
  children: ReactChild | ReactNode;
  className?: ClassValue;
  spacingY?: TStackSpace;
  spacingX?: TStackSpace;
}

const Stack: FC<IStack> = ({ children, className, spacingX = 0, spacingY = 0 }) => {
  return (
    <div className={clsx('flex', className, spaceX[spacingX], spaceY[spacingY])}>{children}</div>
  );
};

export default Stack;
