import React, { ElementType, FC, ReactNode } from 'react';
import clsx, { ClassValue } from 'clsx';

interface IInput {
  as?: ElementType | string;
  label?: string;
  labelAction?: ReactNode;
  labelPlacement?: 'top' | 'left';
  name: string;
  placeholder?: string;
  type?: string;
  className?: ClassValue;
  isRequired?: boolean;
  icon?: ReactNode;
  disabled?: boolean;
  rows?: number;
  leftLabel?: ReactNode;
  leftLabelClassName?: ClassValue;
  actions?: ReactNode;
  variant?: 'normal' | 'white' | 'green';
  height?: 'normal' | 'full';
  value: any;
  onChange?: (value: any) => void;
  isError?: boolean;
}

const variants = {
  normal: clsx(
    'border-gray-300 border',
    'focus:outline-none focus:ring-1 focus:ring-blue-100 focus:border-transparent',
    'hover:outline-none hover:ring-1 hover:ring-blue-100 hover:border-transparent'
  ),
  white: clsx(
    'border-polln-white-500 border',
    'focus:outline-none focus:ring-1 focus:ring-polln-white-500 focus:border-transparent',
    'hover:outline-none hover:ring-1 hover:ring-polln-white-500 hover:border-transparent',
    'bg-polln-gray-300 text-polln-black-300 placeholder-polln-black-400'
  ),
  green: clsx(
    'border-gray-300 border-r border-y',
    'focus:outline-none focus:border-blue-100',
    'hover:outline-none hover:border-blue-100',
    'bg-polln-white-100 text-polln-black-300 placeholder-gray-400'
  )
};

const heights = {
  normal: '',
  full: 'h-full'
};

const Input: FC<IInput> = ({
  as = 'input',
  label,
  labelAction,
  labelPlacement = 'top',
  name,
  placeholder,
  type = 'text',
  className,
  isRequired = false,
  icon,
  disabled = false,
  rows = 4,
  leftLabel,
  leftLabelClassName,
  actions,
  variant = 'normal',
  height = 'normal',
  value,
  onChange,
  isError = false
}) => {
  const Comp = as;

  return (
    <div
      className={clsx(
        'w-full block',
        labelPlacement === 'left' && 'flex items-center space-x-16',
        heights[height]
      )}
    >
      <div
        className={clsx(
          (icon || label) && labelPlacement === 'top' ? 'mb-3' : 'w-24',
          'flex items-center gap-2'
        )}
      >
        <div className="flex space-x-2">
          {icon && icon}
          {label && (
            <label htmlFor={name} className="text-sm">
              {label} {isRequired && <span className="text-red-800">*</span>}
            </label>
          )}
        </div>
        <div>{labelAction && labelAction}</div>
      </div>

      <div
        className={clsx(
          'flex items-center relative',
          labelPlacement === 'left' && 'grow',
          heights[height]
        )}
      >
        {leftLabel && (
          <div className={clsx(leftLabelClassName)}>
            <span className="text-gray-400">{leftLabel}</span>
          </div>
        )}

        <Comp
          id={name}
          type={type}
          name={name}
          placeholder={placeholder}
          className={clsx(
            variants[variant],
            'w-full p-2 disabled:bg-gray-100',
            className,
            isError &&
              clsx(
                'border border-red-400',
                'focus:outline-none focus:ring-1 focus:ring-red-400 focus:border-transparent',
                'hover:outline-none hover:ring-1 hover:ring-red-400 hover:border-transparent'
              )
          )}
          value={value}
          onChange={(e: any) => onChange && onChange(e.target.value)}
          min={0}
          disabled={disabled}
          rows={rows}
        />

        {actions && <div className="absolute right-0 top-0 h-full">{actions}</div>}
      </div>
    </div>
  );
};

export default Input;
