import { createContext, useContext } from 'react';

type TModalSaving = { isSaving: boolean; setIsSaving: (x: boolean) => void };

export const ModalSaving = createContext<TModalSaving>({
  isSaving: false,
  setIsSaving: (x: boolean) => {}
});

export const useModalSaving = () => useContext(ModalSaving);
