import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { IMenuTypes } from 'utils/models';
import useAuth from 'hooks/useAuth';

// components
import Header from 'components/authenticated/Header';
import SideBar from 'components/SideBar';
import clsx from 'clsx';

interface IAuthLayout {
  hasTopBar?: boolean;
  hasSideBar?: boolean;
}

const AuthLayout: FC<IAuthLayout> = ({ hasSideBar = false, hasTopBar = false }) => {
  const { logout, isAdmin } = useAuth();
  const navigate = useNavigate();

  // sticky top-0 z-50
  const menu: IMenuTypes[] = [
    {
      label: 'Notifications',
      to: '/',
      disabled: true
    },

    {
      label: 'Change Password',
      to: '/change-password'
    },
    {
      label: 'Settings',
      to: '/profile'
    },
    {
      label: 'Logout',
      className: 'text-gray-0',
      onClick: () => {
        logout();
        navigate('/login', { replace: true });
      }
    }
  ];

  return (
    <main className="flex flex-col h-screen bg-[#F5F4F3]">
      <div className="flex flex-1 overflow-hidden">
        <div className="flex flex-1 flex-col">
          {hasTopBar && (
            <div
              className={clsx(
                'flex flex-col bg-white relative z-10',
                'md:shadow-md',
                !isAdmin && 'px-3 py-0.5'
              )}
            >
              <Header menu={menu} showNotification />
            </div>
          )}

          <div className="flex flex-1 overflow-y-auto h-full">
            {hasSideBar && (
              <div className="h-full">
                <SideBar />
              </div>
            )}

            <div className="flex flex-col flex-1 overflow-y-auto ">
              <div className="mx-auto w-full pt-5" id="main">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AuthLayout;
