import { ISelectOptions, IYesOrNo, ITimeOptionType, IStatusFilter } from './models';

export const MOBILE_PREFIX = '+61';

export const YESORNO: IYesOrNo[] = [
  {
    value: 1,
    label: 'Yes'
  },
  {
    value: 2,
    label: 'No'
  }
];

export const STATUS_TYPES = [
  {
    value: '',
    text: 'All',
    className: 'type-gray'
  },
  {
    value: 'APPLICATION',
    text: 'Application Consultation',
    className: 'type-blu'
  },
  {
    value: 'MONITORING',
    text: 'Monitoring Consultation',
    className: 'type-grn'
  },
  {
    value: 'SUPPORT',
    text: 'Support Consultation',
    className: 'type-yel'
  },
  {
    value: 'CONSULTED',
    text: 'Consulted',
    className: 'type-red'
  },
  {
    value: 'TGAAPPLIED',
    text: 'TGA Applied',
    className: 'type-red'
  },
  {
    value: 'TGAAPPROVED',
    text: 'TGA Approved',
    className: 'type-red'
  },
  {
    value: 'TGADECLINED',
    text: 'TGA Declined',
    className: 'type-red'
  },
  {
    value: 'TGADECLINEDCLOSED',
    text: 'TGA Declined-closed',
    className: 'type-gray'
  },
  {
    value: 'SCRIPTSENT',
    text: 'Script Sent',
    className: 'type-gray'
  },
  {
    value: 'FOLLOWEDUP',
    text: 'Followed Up',
    className: 'type-gray'
  }
];

export const IS_DEV = ['development', 'qa', 'staging'];

export const TIMEZONES: ISelectOptions[] = [
  {
    value: 'Australia/Eucla',
    label: '(GMT+8:45) Eucla'
  },
  {
    value: 'Australia/Perth',
    label: '(GMT+8:00) AWST - Perth'
  },
  {
    value: 'Australia/Darwin',
    label: '(GMT+9:30) ACST - Darwin'
  },
  {
    value: 'Australia/Brisbane',
    label: '(GMT+10:00) AEST - Brisbane'
  },
  {
    value: 'Australia/Lindeman',
    label: '(GMT+10:00) AEST - Lindeman'
  },
  {
    value: 'Australia/Adelaide',
    label: '(GMT+10:30) ACDT - Adelaide'
  },
  {
    value: 'Australia/Broken_Hill',
    label: '(GMT+10:30) ACDT - Broken Hill'
  },
  {
    value: 'Australia/ACT',
    label: '(GMT+11:00) AEST - ACT'
  },
  {
    value: 'Australia/Hobart',
    label: '(GMT+11:00) AEDT - Hobart'
  },
  {
    value: 'Australia/Melbourne',
    label: '(GMT+11:00) AEDT - Melbourne'
  },
  {
    value: 'Australia/Sydney',
    label: '(GMT+11:00) AEDT - Sydney'
  }
];

export const GENDER_OPTIONS: ISelectOptions[] = [
  {
    value: 'MALE',
    label: 'Male'
  },
  {
    value: 'FEMALE',
    label: 'Female'
  },
  {
    value: 'NOTTOSAY',
    label: 'Preferred not to say'
  },
  {
    value: 'OTHERS',
    label: 'Other'
  }
];

export const TIME_OPTIONS: ITimeOptionType[] = [
  {
    label: 'N/A',
    value: '',
    disabled: true
  },
  {
    label: '12:00 AM',
    value: '00:00'
  },
  {
    label: '12:30 AM',
    value: '00:30'
  },
  {
    label: '01:00 AM',
    value: '01:00'
  },
  {
    label: '01:30 AM',
    value: '01:30'
  },
  {
    label: '02:00 AM',
    value: '02:00'
  },
  {
    label: '02:30 AM',
    value: '02:30'
  },
  {
    label: '03:00 AM',
    value: '03:00'
  },
  {
    label: '03:30 AM',
    value: '03:30'
  },
  {
    label: '04:00 AM',
    value: '04:00'
  },
  {
    label: '04:30 AM',
    value: '04:30'
  },
  {
    label: '05:00 AM',
    value: '05:00'
  },
  {
    label: '05:30 AM',
    value: '05:30'
  },
  {
    label: '06:00 AM',
    value: '06:00'
  },
  {
    label: '06:30 AM',
    value: '06:30'
  },
  {
    label: '07:00 AM',
    value: '07:00'
  },
  {
    label: '07:30 AM',
    value: '07:30'
  },
  {
    label: '08:00 AM',
    value: '08:00'
  },
  {
    label: '08:30 AM',
    value: '08:30'
  },
  {
    label: '09:00 AM',
    value: '09:00'
  },
  {
    label: '09:30 AM',
    value: '09:30'
  },
  {
    label: '10:00 AM',
    value: '10:00'
  },
  {
    label: '10:30 AM',
    value: '10:30'
  },
  {
    label: '11:00 AM',
    value: '11:00'
  },
  {
    label: '11:30 AM',
    value: '11:30'
  },
  {
    label: '12:00 PM',
    value: '12:00'
  },
  {
    label: '12:30 PM',
    value: '12:30'
  },
  {
    label: '01:00 PM',
    value: '13:00'
  },
  {
    label: '01:30 PM',
    value: '13:30'
  },
  {
    label: '02:00 PM',
    value: '14:00'
  },
  {
    label: '02:30 PM',
    value: '14:30'
  },
  {
    label: '03:00 PM',
    value: '15:00'
  },
  {
    label: '03:30 PM',
    value: '15:30'
  },
  {
    label: '04:00 PM',
    value: '16:00'
  },
  {
    label: '04:30 PM',
    value: '16:30'
  },
  {
    label: '05:00 PM',
    value: '17:00'
  },
  {
    label: '05:30 PM',
    value: '17:30'
  },
  {
    label: '06:00 PM',
    value: '18:00'
  },
  {
    label: '06:30 PM',
    value: '18:30'
  },
  {
    label: '07:00 PM',
    value: '19:00'
  },
  {
    label: '07:30 PM',
    value: '19:30'
  },
  {
    label: '08:00 PM',
    value: '20:00'
  },
  {
    label: '08:30 PM',
    value: '20:30'
  },
  {
    label: '09:00 PM',
    value: '21:00'
  },
  {
    label: '09:30 PM',
    value: '21:30'
  },
  {
    label: '10:00 PM',
    value: '22:00'
  },
  {
    label: '10:30 PM',
    value: '22:30'
  },
  {
    label: '11:00 PM',
    value: '23:00'
  },
  {
    label: '11:30 PM',
    value: '23:30'
  }
];

export const statusTypes: IStatusFilter[] = [
  {
    value: '',
    text: 'All',
    className: 'type-gray'
  },
  {
    value: 'APPLICATION',
    text: 'Application Consultation',
    className: 'type-blu'
  },
  {
    value: 'MONITORING',
    text: 'Monitoring Consultation',
    className: 'type-grn'
  },
  {
    value: 'SUPPORT',
    text: 'Support Consultation',
    className: 'type-yel'
  },
  {
    value: 'CONSULTED',
    text: 'Consulted',
    className: 'type-red'
  },
  {
    value: 'TGAAPPLIED',
    text: 'TGA Applied',
    className: 'type-red'
  },
  {
    value: 'TGAAPPROVED',
    text: 'TGA Approved',
    className: 'type-red'
  },
  {
    value: 'TGADECLINED',
    text: 'TGA Declined',
    className: 'type-red'
  },
  {
    value: 'TGADECLINEDCLOSED',
    text: 'TGA Declined-closed',
    className: 'type-gray'
  },
  {
    value: 'SCRIPTSENT',
    text: 'Script Sent',
    className: 'type-gray'
  },
  {
    value: 'FOLLOWEDUP',
    text: 'Followed Up',
    className: 'type-gray'
  }
];

export const PROFILE_PHOTO_TYPES = ['image/jpg', 'image/jpeg', 'image/png'];

export const WEEKS: ISelectOptions[] = [
  {
    value: 1,
    label: '1 week'
  },
  {
    value: 2,
    label: '2 weeks'
  },
  {
    value: 3,
    label: '3 weeks'
  },
  {
    value: 4,
    label: '4 weeks'
  },
  {
    value: 5,
    label: '5 weeks'
  },
  {
    value: 6,
    label: '6 weeks'
  }
];

export const DAYS_OF_WEEKS: string[] = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];

export const DAYS_OF_WEEKS_OPTION: ISelectOptions[] = [
  {
    label: 'S',
    value: 'SUN'
  },
  {
    label: 'M',
    value: 'MON'
  },
  {
    label: 'T',
    value: 'TUE'
  },
  {
    label: 'W',
    value: 'WED'
  },
  {
    label: 'Th',
    value: 'THU'
  },
  {
    label: 'F',
    value: 'FRI'
  },
  {
    label: 'S',
    value: 'SAT'
  }
];

export const TABLE_PER_PAGE: ISelectOptions[] = [
  {
    label: '5 per page',
    value: 5
  },
  {
    label: '10 per page',
    value: 10
  },
  {
    label: '20 per page',
    value: 20
  },
  {
    label: '25 per page',
    value: 25
  },
  {
    label: '50 per page',
    value: 50
  },
  {
    label: '100 per page',
    value: 100
  }
];

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 24
};

export const STATE_OPTIONS: ISelectOptions[] = [
  { label: 'ACT', value: 'ACT' },
  { label: 'NSW', value: 'NSW' },
  { label: 'NT', value: 'NT' },
  { label: 'QLD', value: 'QLD' },
  { label: 'SA', value: 'SA' },
  { label: 'TAS', value: 'TAS' },
  { label: 'VIC', value: 'VIC' },
  { label: 'WA', value: 'WA' }
];

export const STEPS_ONBOARD = {
  1: 'Next',
  2: 'Complete'
};

export const EVENTS_COLOR = {
  INITIAL: {
    color: '#8D3E30',
    title: 'Initial Consultation'
  },
  STANDARD: {
    color: '#CE744B',
    title: 'Standard Consultation'
  },
  SHORT: {
    color: '#0F5342',
    title: 'Short Consultation'
  },
  STANDARD_FOLLOWUP: {
    color: '#CE744B',
    title: 'Standard Follow-up Consultation'
  },
  EXTENDED_FOLLOWUP: {
    color: '#0F5342',
    title: 'Extended Follow-up Consultation'
  },
  NURSE: {
    color: '#0F5342',
    title: 'Nurse Consultation'
  }
};

export const DEFAULT_ALL_DAY_MINUTES = 60;

export const VIEW_CONSULTATION: string[] = ['DONE', 'DIDNOTATTEND'];
export const CONT_CONSULTATION: string[] = ['ONCALL', 'CALLEND', 'POSTCALL'];
